*{
  font-family: 'Rubik', sans-serif;
  margin:0;
  padding:0;
  box-sizing:border-box;
  scroll-behavior: smooth;
  /* width:100vw; */
}
:root{
  --orange:#FDBF6F;
  --red:#FB80AA;
  --green:#33A02C;
  --dark_blue:#0A1846;
  --blue:#1F78B4;
  --black:#363636;
  --light_blue:#A6CEE3;
  --deep_red:#E31A1C;
  --light_green:#B2DF8A;
}
html { 
  scroll-behavior: smooth;
 } 
h1,
h2,
h3,
h4,
h5,
h6,
p{
  padding:0px;
  margin:0px;
}
body {
  /* width:100%; */
  /* height:100%; */
}
.App .loader{
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}
.App .loader p{
  font-size:18px;
  color: var(--black);
  font-family: "Medium";
}