:root{
    --orange:#FDBF6F;
    --yellow:#FBD19B;
    --red:#FB80AA;
    --green:#33A02C;
    --dark_blue:#0A1846;
    --blue:#1F78B4;
    --black:#363636;
    /* --black:#3B3B3B; */
    --light_blue:#A6CEE3;
    --deep_red:#E31A1C;
    --light_green:#B2DF8A;
  }
/* section 1 */
  .info{
    width: 100%;
    /* background-color: black; */
  }
  .info .info_area{
    /* background-color: red; */
  }
  .info .info_area .background{
      position: absolute;
      margin: auto;
      right: 0;
      bottom: 20px;
      left: 0; 
      z-index: 10;
      width: 90%;
      height: 100%;
      background: linear-gradient(45deg,#FBCE8B 28.24%, #FB80AA 57.76%, #40DED2 91.22%);
      -moz-filter:blur(100px);
      -o-filter:blur(100px);
      -webkit-filter:blur(100px);
  }
 /* coin 2 */
 .info .info_area .coin2{
      width: 230px;
      height: 230px;
      position: absolute;
      z-index: 100;
      top: 100px;
      right: 100px;
      animation-duration: 7s;
      animation-iteration-count: infinite;
      animation-name: bounce;
      animation-timing-function: ease-in;
  }
  .info .info_area .coin2 img{
      width: 100%;
      height: 100%;
      object-fit: contain;
  }
  @keyframes bounce {
        0%   { transform: translateY(0); }
        50%  { transform: translateY(-70px); }
        100% { transform: translateY(0); }

    }
    .info .info_area .div{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      z-index: 1000;
      width: 100%;
      height: 400px;
  }
  .info .info_area .div .content{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      row-gap: 2rem;
      animation-duration: 1s;
      animation-name: anim1;
      animation-timing-function: ease;
      /* background-color: red; */
      margin-top:6rem;
      position: relative;
  }
  .info .info_area .div .content p:nth-child(1){
      font-size: 45px;
      text-align: center;
      font-family: "Bold";
      color: var(--dark_blue); 
      max-width: 700px;
      line-height: 50px;
      margin-bottom:1rem 0rem;
  }
  .info .info_area .div .content p:nth-child(2){
      font-size: 17px;
      text-align: center;
      font-family: "Medium";
      color: var(--black); 
      max-width: 900px;
      line-height: 35px;
  }
  .info .info_ft{
      margin:8rem 8rem;
      position: relative;
      z-index: 1000;
      position: relative;
      background-image: url("../../assets/imgs/background.svg");
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;
  }
  .info .info_ft .info_grid{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      column-gap: 2rem;
      margin-bottom:5rem;
    }
.info .info_ft .info_grid.reverse{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    margin-bottom:5rem;
}
.info  .info_ft .info_grid .info_img{
    flex-basis: 40%;
    width: 400px;
    height: 400px;
}
.info .info_ft .info_grid .info_img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.info .info_ft .info_grid .info_heading{
    flex-basis: 60%;
    display: flex;
    flex-direction: column;
}
.info .info_ft .info_grid .info_heading p:nth-child(1){
    margin-bottom: 1rem;
    font-family: "Bold";
    font-size: 20px;
    letter-spacing: 0.2px;
    line-height: 35px;
    /* padding:10px 0px; */
    color:var(--dark_blue);
}
.info .info_ft .info_grid .info_heading p:nth-child(2){
    margin-bottom: 1rem;
    font-family: "Medium";
    font-size: 17px;
    letter-spacing: 0.2px;
    line-height: 35px;
    color:var(--black);
}
.info .voting_ft{
    margin: 8.5rem 0rem;
    position: relative;
}
.info .voting_ft .ft_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.info .voting_ft .ft_container .ft_grid{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    padding:30px 30px;
    background-color: var(--yellow);
}
.info .voting_ft .ft_container  .ft_grid img{
    width: 100%;
    height: 120px;
    flex-basis: 20%;
}
.info .voting_ft .ft_container .ft_grid p{
    max-width: 400px;
    font-size: 17px;
    text-align: center;
    font-family: "Medium";
    color: var(--black); 
    text-align: left;
    flex-basis: 80%;
    line-height: 30px;
}
.info .voting_ft .heart_floating{
    position: absolute;
    width: 100%;
    height: 300px;
    z-index: 100;
    top: 80px;
    left: 250px;
}
.info .activity_ft{
    background: linear-gradient(137.32deg, #FBCE8B 33.11%, #FB80AA 64.74%, #40DED2 90.55%);
    width: 100%;
}
.info .activity_ft .activity_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.info .activity_ft .activity_container .activity_text{
    display: flex;
    flex-direction: column;
    padding:50px 0px;
}
.info .activity_ft .activity_container .activity_text p{
    font-size: 17px;
    text-align: center;
    font-family: "Medium";
    color: var(--black); 
    text-align: left;
    line-height: 34px;
}
.info .activity_ft .activity_container .activity_text  ul{
    margin-left: 30px;
}

.info .join_ft{
    margin:6rem 0rem;
    background-color: white;
    background-image: url("../../assets/imgs/background.svg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}
.info .join_ft .join_container{
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 340px;
}
.info .join_ft .join_container .join_grid{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 4rem;
}
.info .join_ft .join_container .join_grid p{
    font-size: 40px;
    text-align: center;
    font-family: "Bold";
    color: var(--dark_blue); 
    text-align: center;
    line-height: 52px; 
    max-width: 700px;
}
.info .join_ft .join_container .join_grid  a{
    font-family: "Bold";
    background-color: var(--dark_blue);
    color: white;
    outline: none;
    border:none;
    width: 220px;
    height: 60px;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    transition: 0.3s ease-in;
    display:flex;
    justify-content:center;
    align-items: center;
    text-decoration: none;
  }
  .info .join_ft .join_container .join_grid  a:hover{
    transition: 0.3s ease-in;
    transform: translateY(-10px);
  }
  @media screen and (max-width:1100px){
    .info .voting_ft .heart_floating{
        display: none;
    }
}
@media screen and (max-width:900px){
    .info .info_area .div .content p:nth-child(2){
        font-size: 16px;
        color: var(--black); 
        line-height: 35px;
        margin:0rem 4rem;
    }
   
}
@media screen and (max-width:769px){
    .info .info_area{
      margin:0rem 0rem;
    }
    .info .info_area .coin2{
        width: 100px;
        height: 100px;
        top: 180px;
        right: 30px;
    }
    .info .info_area .div .content p:nth-child(1){
        font-size: 35px;
        /* max-width: 100%; */
        line-height: 50px;
        margin:0rem 2rem;
    }
    .info .info_area .div .content p:nth-child(2){
        font-size: 16px;
        color: var(--black); 
        line-height: 35px;
        margin:0rem 2rem;
    }
    .info .info_ft{
        margin:4rem 2rem;
    }
    .info .info_ft .info_grid{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      margin-bottom:2rem;
    }
    .info .info_ft .info_grid.reverse{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      margin-bottom:2rem;
    }
    
    .info .info_ft .info_grid .info_img{
      width: 280px;
      height: 280px;
      margin-bottom: 2rem;
    }
    .info .info_ft .info_grid .info_heading p:nth-child(2){
        font-size: 16px;
    }
    .info .activity_ft .activity_container .activity_text{
        margin:0rem 2rem;
    }
    .info .join_ft .join_container .join_grid p{
        font-size: 30px;
        line-height: 50px;
        max-width: 300px;
    }
  }
  @media screen and (max-width:600px){
    .info .info_area .div .content p:nth-child(1){
        padding-top: 5rem;
    }
    .info .info_area .div .content p:nth-child(2){
        max-width: 900px;
    }
    .info .voting_ft .ft_container .ft_grid{
        margin:0rem 2rem;
        padding:30px 30px;
        align-items: flex-start;
        flex-direction: column;
    }
    .info .voting_ft .ft_container .ft_grid img{
        width: 30px;
        height: 30px;
        margin-bottom: 20px;
    }
  }
  @media screen and (max-width:500px){
    .info .info_grid{
        padding-top:2rem;
    }
    .info .info_area .div .content p:nth-child(1){
        font-size: 30px;
        max-width: 100%;
        line-height: 40px;
        margin:0rem 2rem;
    }
    .info .info_area .div .content p:nth-child(2){
        max-width: 600px;
    }
    .info .info_ft .info_grid .info_img{
        width: 200px;
        height: 200px;
        margin-bottom: 2rem;
    }
    .info .voting_ft .ft_container .ft_grid{
        margin:0rem 2rem;
        padding:30px 10px;
    }
  }