:root{
    --orange:#FDBF6F;
    --red:#FB80AA;
    --green:#33A02C;
    --dark_blue:#0A1846;
    --blue:#1F78B4;
    --black:#363636;
    --light_blue:#A6CEE3;
    --deep_red:#E31A1C;
    --light_green:#B2DF8A;
  }

  .about{
    position: relative;
    background-image: url("../../../assets/imgs/background.svg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
  }
  .about .about_area{
    position: relative;
    z-index: 1000;
    margin:10rem 4rem;
  }
 
  .about .about_area .about_grid{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    column-gap: 2rem;
    margin-bottom:10rem;
  }
  .about .about_area .about_grid.reverse{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    margin:5rem 0rem;
  }
  .about .about_area .about_grid .meeting_img{
    flex-basis: 40%;
    width: 400px;
    height: 400px;
  }
  .about .about_area .about_grid .meeting_img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .about .about_area .about_grid .meeting_text{
    flex-basis: 60%;
    display: flex;
    flex-direction: column;
  }
  .about .about_area .about_grid .meeting_text .heading{
    margin-bottom: 1rem;
  }
  
  .about .about_area .about_grid .meeting_text p{
    font-size: 16px;
    font-family: "Medium";
    letter-spacing: 0.2px;
    line-height: 35px;
    max-width:40rem;
  }
  .about .about_area .about_grid .meeting_text #heading{
    font-family: "Bold";
    font-size: 20px;
    padding:10px 0px;
  }
  .about .about_area .about_grid .meeting_text .bullet_list{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin:0.5rem 0rem;
  }
  .about .about_area .about_grid .meeting_text .bullet_list p{ 
    margin-left:20px;
    font-size: 16px;
    font-family: "Medium";
  }
  .about .about_area .about_grid .meeting_text button{
    font-family: "Bold";
    box-sizing: border-box;
    outline:none;
    background-color: transparent;
    border: 2px solid var(--dark_blue);
    color: var(--dark_blue);
    width: 160px;
    height: 60px;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    transition: 0.3s ease-in;
    margin:1rem 0rem;
  }

  @media screen and (max-width:769px){
    .about .about_area{
      margin:0rem 2rem;
    }
    .about .about_area .about_grid{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      margin-top:4rem;
      margin-bottom:2rem;
    }
    .about .about_area .about_grid.reverse{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
    }
    
    .about .about_area .about_grid .meeting_img{
      width: 280px;
      height: 280px;
      margin-bottom:1rem;
    }
  }
  @media screen and (max-width:500px){
    .about .about_area{
      margin:10rem 2rem;
    }
    .about .about_area .about_grid{
      column-gap: 1rem;
      margin-bottom:2rem;
    }
    .about .about_area .about_grid .meeting_img{
      width: 200px;
      height: 200px;
      margin-bottom:1rem;
    }
  }
