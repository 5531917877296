:root{
  --orange:#FDBF6F;
  --red:#FB80AA;
  --green:#33A02C;
  --dark_blue:#0A1846;
  --blue:#1F78B4;
  --black:#363636;
  --light_blue:#A6CEE3;
  --deep_red:#E31A1C;
  --light_green:#B2DF8A;
}

.map{
  position: relative;
  background-image: url("../../../assets/imgs/background.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  width: 100%;
}
.map .map_area {
  margin:4rem 4rem;
}
.map .map_area .heading{
  margin: 4rem 0rem;
}
.map .map_area .phase_container{
  margin:0rem 0rem;
}
.map .map_area .phase_container .phase_grid{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin:0rem 3rem;
}
.map .map_area .phase_container .phase_grid .start{
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
}
.map .map_area .phase_container .phase_grid .end{
  display: flex;
  justify-content:flex-start ;
  flex-direction: row;
  width: 100%;
  margin-left: 18rem;
  margin-top:-3.6px;
  flex-basis: auto;
  /* background-color: red; */
}
.map .map_area .phase_container .phase_grid .phase_column .line1{
  display: block;
  background: linear-gradient(45deg, #FBCE8B 4.94%, #FB80AA 38.6%, #40DED2 73.69%);
  width: 3px;
  height: 420px;
  margin-right: 20px;
}
.map .map_area .phase_container .phase_grid .phase_column .line2{
  display: block;
  background: linear-gradient(#FBCE8B 4.94%, #FB80AA 38.6%, #40DED2 73.69%);
  width: 3px;
  height: 420px;
  margin-right: 20px;
}
.map .map_area .phase_container .phase_grid .phase_column .points{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top:20px;
}
.map .map_area .phase_container .phase_grid .phase_column .points h1{
  font-family: "Medium";
  font-size: 24px;
}
.map .map_area .phase_container .phase_grid .phase_column .points .points_row{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
} 
.map .map_area .phase_container .phase_grid .phase_column .points .points_row .points_column{
  display: flex;
  flex-direction: row;
  align-items:center;
  flex-basis: 50%;
  margin-top:20px;
}
.map .map_area .phase_container .phase_grid .phase_column .points .points_row .dot{
  margin-right: 10px;
}
.map .map_area .phase_container .phase_grid .phase_column .points .points_row .point_title{
  font-size: 16px;
  font-family: "Medium";
  width: 100%;
}
.map .map_area .phase_container .vector_list{
  padding:0rem;
  width: 100%;
  height: 100%;
}
.map .map_area .phase_container .vector_list ul{
  list-style-type: none;
}
  
.map .map_area .phase_container .vector_list ul li{
  display: inline-block;
  padding-left:41px;
}
.map .map_area .phase_container .vector_list ul li img{
  width: 100%;
  height: 100%;
}

@media screen and (max-width:1300px){
  .map .map_area .phase_container .vector_list{
    display: none;
  }
}
@media screen and (max-width:1000px){
  .map .map_area .phase_container .phase_grid{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin:0rem 1rem;
  }
  .map .map_area .phase_container .phase_grid .phase_column .line1,
  .map .map_area .phase_container .phase_grid .phase_column .line2{
    display: none;
  }
  
  .map .map_area .phase_container .phase_grid .end{
    display: flex;
    justify-content:flex-start ;
    flex-direction: column;
    width: 100%;
    margin-left: 0rem;
    margin-top:0px;
  }

}
@media screen and (max-width:769px){
  .map .map_area {
  margin:4rem 1rem;
  /* width: 100%; */
  /* background-color: red; */
}
  .map .map_area .heading{
    margin: 1rem 0rem;
  }
  
}