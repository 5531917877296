.title{
    font-size: 50px;
    color: transparent;
    font-family: "Bold";
    background: linear-gradient(90deg, #FBCE8B 4.94%, #FB80AA 38.6%, #40DED2 73.69%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align:left;
}
@media screen and (max-width:769px){
    .title{
        text-align:left;  
        font-size: 40px;
    }
}
@media screen and (max-width:500px){
    .title{
        text-align:left;  
        font-size: 30px;
    }
}