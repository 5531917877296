:root{
    --orange:#FDBF6F;
    --red:#FB80AA;
    --green:#33A02C;
    --dark_blue:#0A1846;
    --blue:#1F78B4;
    --black:#363636;
    --light_blue:#A6CEE3;
    --deep_red:#E31A1C;
    --light_green:#B2DF8A;
  }

  .token{
    position: relative;
    background-image: url("../../../assets/imgs/background.svg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    width: 100%;
  }
  .token .token_area {
    margin:4rem 4rem;
  }
  .token .token_area .heading{
    margin: 5rem 0rem;
  }
  .token .token_area .chart{
    width:100%;
    height:100%;
  }
  .token .token_area .chart img{
    width:100%;
    height:100%;
    object-fit: contain;
  }
  .token .token_area .details{
    margin:0rem 2rem;
    margin-top:4rem;
  }
  .token .token_area .details .t_header{
    color:var(--dark_blue);
    font-size:26px;
    font-family:"Heavy";
    text-align:center;
  }
  .token .token_area .details .details_grid{
    text-align: center;
    display:grid;
    grid-template-columns: repeat(5,1fr);
    margin:3rem 0rem;
    padding:5px 0px;
    /* background-color: red; */
  }
  .token .token_area .details .details_grid .details_item{
    display:flex;
    flex-direction:column;
    row-gap:0.5rem;
  }
  .token .token_area .details .details_grid .details_item .d_title{
    color:var(--deep_grey);
    font-size:18px;
    font-family:"Medium"; 
  }
  .token .token_area .details .details_grid .details_item .d_name{
    color:var(--dark_blue);
    font-size:18px;
    font-family:"Heavy"; 
  }
  .token .token_area .text{
    margin:0rem 4rem;
  }
  .token .token_area .text p{
    padding:5px 0px;
  }
  .token .token_area .text p span{
    padding-left:10px;
  }
  .token .token_area .text .text_1{
    font-size: 24px;
    font-family: "Medium";
  }
  .token .token_area .text .text_2{
    font-size: 17px;
    font-family: "Medium";
    padding-left:5rem;
    /* text-align: right; */
  }
  .token .token_area .text .text_3{
    font-size: 17px;
    font-family: "Medium";
    padding-left:5rem;
    /* text-align: right; */
  }
  .token .token_area .text .text_4{
    font-size: 17px;
    font-family: "Medium";
    padding-left:5rem;
    /* text-align: right; */
  }
  .token .token_area .item_container{
    display:flex;
    justify-content:space-between;
    align-items: center;
    flex-direction: row;
    margin:2rem 4rem;
  }
  .token .token_area .item_container .item_column {
    display:flex;
    justify-content:space-between;
    align-items: flex-start;
    align-self: start;
    flex-direction: column;
    width: 100%;
  }
  .token .token_area .item_container .item_column .row_item{
    flex-basis: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin:15px 0px
  }
  .token .token_area .item_container .item_column .row_item p{
    font-size: 16px;
    font-family: "Medium";
    color: black;
  }
  .token .token_area .item_container .item_column .row_item .percent{
    font-size: 16px;
    font-family: "Bold";
    margin-left:20px;
  }
  .token .token_area .item_container .item_column .row_item #percent1{
   color: var(--red);
  }
  .token .token_area .item_container .item_column .row_item #percent2{
    color: var(--light_blue);
  }
  .token .token_area .item_container .item_column .row_item #percent3{
    color: var(--orange);
  }
  .token .token_area .item_container .item_column .row_item #percent4{
    color: var(--deep_red);
  }
  .token .token_area .item_container .item_column .row_item #percent5{
    color: #33A02C
  }
  .token .token_area .item_container .item_column .row_item #percent6{
   color: var(--blue);
  }
  .token .token_area .item_container .item_column .row_item #percent7{
    color: var(--light_green);
  }
  .token .token_area .transaction_text{
    margin:2rem 4rem;
    display: flex;
    flex-direction: column;
    row-gap:1rem;
  }
  .token .token_area .transaction_text p:nth-child(1){
    color:black;
    font-size:18px;
    letter-spacing:0px;
    font-family:"Bold"
  }
  .token .token_area .transaction_text p:nth-child(2){
    color:black;
    font-size:16px;
    letter-spacing:0px;
    font-family:"Medium";
    line-height:30px;
    max-width:58rem;
  }
  @media screen and (max-width:1000px){
    .token .token_area {
      margin:4rem 1rem;
    }
  }

  @media screen and (max-width:769px){
    .token .token_area {
      margin:4rem 1rem;
    }
    .token .token_area .item_container{
      display:flex;
      justify-content:space-between;
      align-items: center;
      flex-direction: column;
      margin:0rem 0rem;
    }
    .token .token_area .transaction_text{
      margin:2rem 0rem;
    }
    .token .token_area .item_container .item_column .row_item{
      margin:10px 0px
    }
    .token .token_area .details{
      margin:2rem 0rem;
    }
    .token .token_area .details .details_grid{
      text-align: left;
      display:grid;
      grid-template-columns: repeat(3,1fr);
    }
    .token .token_area .text{
      margin:0rem 0rem;
    }
    .token .token_area .text .text_2,
    .token .token_area .text .text_3,
    .token .token_area .text .text_4{
      font-size: 16px;
      font-family: "Medium";
      padding-left:1rem;
    }
  }
  @media screen and (max-width:500px){
    .token .token_area {
      margin:4rem 1rem;
    }
    .token .token_area .heading{
      margin: 2rem 0rem;
    }
    .token .token_area .details .details_grid{
      text-align: left;
      display:grid;
      grid-template-columns: repeat(2,1fr);
      row-gap:2rem;
      margin:1.8rem 0rem;
    }
    .token .token_area .details .details_grid .details_item .d_title,
    .token .token_area .details .details_grid .details_item .d_name{
    font-size:16px;
    max-width: 150px;
  }
  .token .token_area .text .text_1 {
    font-size: 18px;
    font-family: "Medium";
    margin-bottom: 1rem;
}
  
  }