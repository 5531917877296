
:root{
    --orange:#FDBF6F;
    --red:#FB80AA;
    --green:#33A02C;
    --dark_blue:#0A1846;
    --blue:#1F78B4;
    --black:#363636;
    --light_blue:#A6CEE3;
    --deep_red:#E31A1C;
    --light_green:#B2DF8A;
  }
.steps {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.step-label {
  width: 100%;
  padding-top: 10%;
  font-size: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
}

.step-content {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.wallet-input,
.email-input {
  min-width: 100px;
  flex-grow: 1;
  height: 20px;
}

.submit-btn {
  width: auto;
  color: white;
  height: 100%;
  flex-grow: 0;
}

/* section 1 */
  .hero{
    margin:0rem 0rem;
    width: 100%;
  }
  .hero .hero_area{
      width: 100%;
  }
  .hero .hero_area .background{
      position: absolute;
      margin: auto;
      right: 0;
      bottom: 20px;
      left: 0; 
      z-index: 10;
      width: 90%;
      height: 75%;
      background: linear-gradient(45deg,#FBCE8B 28.24%, #FB80AA 57.76%, #40DED2 91.22%);
      -moz-filter:blur(100px);
      -o-filter:blur(100px);
      -webkit-filter:blur(100px);
  }
  /* coin1 */
  .hero .hero_area .coin1{
      width: 230px;
      height: 230px;
      position: absolute;
      z-index: 5;
      bottom: 20px;
      left: 80px;
      animation-duration: 5s;
      animation-iteration-count: infinite;
      animation-name: bounce;
      animation-timing-function: ease;
  }
  .hero .hero_area .coin1 img{
      width: 100%;
      height: 100%;
      object-fit: contain;
  }
  /* coin 2 */
  .hero .hero_area .coin2{
      width: 230px;
      height: 230px;
      position: absolute;
      z-index: 5;
      top: 100px;
      right: 100px;
      animation-duration: 7s;
      animation-iteration-count: infinite;
      animation-name: bounce;
      animation-timing-function: ease-in;
  }
  .hero .hero_area .coin2 img{
      width: 100%;
      height: 100%;
      object-fit: contain;
  }
  /* coin 3 */
  .hero .hero_area .coin3{
      width: 200px;
      height: 200px;
      position: absolute;
      z-index: 100;
      bottom: 50px;
      right:240px;
      animation-duration: 7s;
      animation-iteration-count: infinite;
      animation-name: bounce;
      animation-timing-function: ease-out;
  }
  .hero .hero_area .coin3 img{
      width: 100%;
      height: 100%;
      object-fit: contain;
  }
  /* coin 4 */
  .hero .hero_area .coin4{
      width: 200px;
      height: 200px;
      position: absolute;
      z-index: 100;
      top: 120px;
      left:190px;
      animation-duration: 7s;
      animation-iteration-count: infinite;
      animation-name: bounce;
      animation-timing-function: ease;
  }
  .hero .hero_area .coin4 img{
      width: 100%;
      height: 100%;
      object-fit: contain;
  }
  @keyframes bounce {
        0%   { transform: translateY(0); }
        50%  { transform: translateY(-70px); }
        100% { transform: translateY(0); }

    }
  .hero .hero_area .div{
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      z-index: 1000;
      width: 100%;
      height: auto;
      /* background-color: red; */
  }
  .hero .hero_area .div .content{
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      row-gap: 1rem;
      animation-duration: 1s;
      animation-name: anim1;
      animation-timing-function: ease;
      /* background-color: red; */
      margin-top: 0px;
  }
  .step-label {
      font-size: 30px;
      text-align: center;
      font-family: "Bold";
      color: white;
      letter-spacing: 2.5px;
      margin-top: 0;
      max-width:55rem;
      line-height: 90px;
  }
  .hero .hero_area .div .content p{
      font-size: 16px;
      text-align: center;
      font-family: "Medium";
      color: white; 
      max-width: 500px;
      line-height: 25px;
      margin-bottom:1rem 0rem;
  }
  
  .hero .hero_area .div .content a{
    font-family: "Bold";
    background-color: var(--dark_blue);
    color: white;
    outline: none;
    border:none;
    width: 220px;
    height: 60px;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    transition: 0.3s ease-in;
    text-decoration: none;
    display:flex;
    justify-content:center;
    align-items:center;
  }
  .hero .hero_area .div .content a:hover{
    transition: 0.3s ease-in;
    transform: translateY(-10px);
  }
 
  .hero .hero_area .div .content .featured .grid{
      display: flex; 
      overflow-x: auto;
      justify-content: space-between;
      align-items: center; 
      column-gap: 4rem;
      row-gap: 2rem;
      text-align: center;
      /* max-width: 500px; */
      margin-top: 40px;
    }
.hero .hero_area .div .content .featured .grid:hover{
      animation-play-state: paused;
    }
  .hero .hero_area .div .content .featured .grid::-webkit-scrollbar{
      width: 0;
  }
  .hero .hero_area .div .content .featured img{
      width: 100%;
      height: 28px;
      cursor: pointer;
      transition: 0.3s ease-in;
      text-align: center;
  }
  

  @keyframes button-bounce {
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-20px); }
    100% { transform: translateY(0px); }
  }

@keyframes anim1 {
        from {
            transform: translateY(100px);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;  
        }
}
@media screen and (max-width:1100px){
    .step {
        width: 100%;
    }
    .hero .hero_area .background{
        position: absolute;
        margin: auto;
        right: 0;
        top: 0;
        left: 0; 
        width: 100%;
        background: linear-gradient(45deg,#FBCE8B 28.24%, #FB80AA 57.76%, #40DED2 91.22%);
        filter: blur(80px);
      }
}

@media screen and (max-width:769px){
    .step {
        width: 100%;
    }
    .hero .hero_area .background{
        width: 100%;
        height: 100%;
      }
    .hero .hero_area .coin1{
        width: 80px;
        height: 80px;
        bottom: -40px;
        left: 30px;
        z-index:100;
    }
    .hero .hero_area .coin2{
        width: 80px;
        height: 80px;
        top: 100px;
        right: 30px;
        z-index:100;
    }
    .hero .hero_area .coin3{
        width: 80px;
        height: 80px;
        bottom: -40px;
        right:50px;
        z-index:5;
    }
    .hero .hero_area .coin4{
        width: 80px;
        height: 80px;
        top: 100px;
        left:50px;
        z-index:5;
    }
    .step-label {
      font-size: 30px;
      line-height:60px;
    }
      
}

@media screen and (max-width:500px){
    .step {
        width: 100%;
    }
    .step-label {
       font-size: 30px;
       letter-spacing: 1.0px;
       line-height: 50px;
    }
    .hero .hero_area .div .content p{
      font-size: 15px;
      margin:0rem 3rem;
      max-width: 500px;
    }
    .hero .hero_area .div .content a{
        width: 150px;
        height: 50px; 
        top:30px;
        font-size: 12px;
    }
}

@media screen and (max-width:450px){
    .step-label {
        font-size: 40px;
        padding-top: 1rem;
        line-height:50px;
    }
}
