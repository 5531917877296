:root{
  --orange:#FDBF6F;
  --red:#FB80AA;
  --green:#33A02C;
  --dark_blue:#0A1846;
  --blue:#1F78B4;
  --black:#363636;
  --light_blue:#A6CEE3;
  --deep_red:#E31A1C;
  --light_green:#B2DF8A;
}

.faqs{
  position: relative;
  width: 100%;
  background-color: white;
}
.faqs .faqs_area{
  position: relative;
  margin-top:4rem;
  padding:0rem 4rem;
  z-index: 100;
}
.faqs .background{
    position: absolute;
      z-index: 10;
      width: 100%;
      height:  300px;
      background: linear-gradient(20deg, #FBCE8B 10.92%, #FB80AA 52.25%, #40DED2 80.58%);
      filter: blur(443.4px);
}
.faqs .faqs_area .heading{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding:2rem 4rem;
}
.faqs .faqs_area .heading h1{
  font-size: 50px;
  color: black;
  font-family: "Bold";
}
.faqs .faqs_area .faqs_container{
  padding:2rem 4rem;
}
.faqs .faqs_area .faqs_container .item{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom:1rem;
  /* background-color: red; */
}
.faqs .faqs_area .faqs_container .item .text{
  flex-basis: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.faqs .faqs_area .faqs_container .item .text .row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-basis: 95%;
  column-gap: 1rem;

}
.faqs .faqs_area .faqs_container .item .text .row .question{
  font-size: 18px;
  font-family: "Bold";
  color: var(--black);
}
.faqs .faqs_area .faqs_container .item .text .row #highlight{
  color: var(--red);
}
.faqs .faqs_area .faqs_container .item .answer p{
  font-size: 16px;
  font-family: "Medium";
  margin:0.2rem 0rem;
  color: var(--black);
  line-height: 30px;
}
.faqs .faqs_area .faqs_container .item .border{
  display: block;
  background-color: #C4C4C4;
  width: 100%;
  height: 1px;
  margin:20px 0px;
}
.faqs .faqs_area .faqs_container .item .icon{
  cursor: pointer;
  flex-basis:5%;
}
@media screen and (max-width:1000px){
  .faqs .faqs_area{
    padding:0rem 2rem;
  }
}
@media screen and (max-width:769px){
  .faqs .faqs_area{
    padding:0rem 1rem;
  }
  .faqs .faqs_area .heading{
    padding:2rem 1rem;
  }
  .faqs .faqs_area .faqs_container{
    padding:2rem 1rem;
  }
}
@media screen and (max-width:500px){
  .faqs .faqs_area .heading h1{
    font-size: 30px;
  }
  .faqs .faqs_area .faqs_container .item .text .question{
    font-size: 16px;
    font-family: "Bold";
  }
  .faqs .faqs_area .faqs_container .item .answer p{
    font-size: 14px;
  }
}