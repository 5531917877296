body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Medium';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; */
  font-family: 'Medium';
}

@font-face{
  font-family: 'Heavy';
  src: url('./assets/fonts/Gilroy-Bold.ttf') format("truetype"); 
}
@font-face{
  font-family: 'Bold';
  src: url('./assets/fonts/Gilroy-Bold.ttf') format("truetype"); 
}
@font-face{
  font-family: 'Regular';
  src: url('./assets/fonts/Gilroy-Regular.ttf') format("truetype"); 
}
@font-face{
  font-family: 'Light';
  src: url('./assets/fonts/Gilroy-Light.ttf') format("truetype"); 
}
@font-face{
  font-family: 'Medium';
  src: url('./assets/fonts/Gilroy-Medium.ttf') format("truetype"); 
}