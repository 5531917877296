:root{
    --orange:#FDBF6F;
    --red:#FB80AA;
    --green:#33A02C;
    --dark_blue:#0A1846;
    --blue:#1F78B4;
    --black:#363636;
    --light_blue:#A6CEE3;
    --deep_red:#E31A1C;
    --light_green:#B2DF8A;
    --teal:#95D7D8;
    --deep_grey:#3B3B3B;
  }
.presale{
    position: relative;
    background-image: url("../../../assets/imgs/background.svg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    width: 100%;
  }
.presale .presale_area {
      margin:4rem 4rem;
}
.presale .presale_area .heading{
    text-align:center;
    color:var(--dark_blue);
    font-size:26px;
    font-family:"Heavy";
    margin:1rem 0rem;
}
.presale .presale_area .presale_grid {
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    column-gap:5rem;
}
.presale .presale_area .presale_grid .each_item{
    background-color:var(--teal);
    margin:2rem 2rem;
    width:100%;
    padding: 40px 0px;
    border-radius: 16px;
    cursor: pointer;
}
.presale .presale_area .presale_grid .each_item .column{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap:10px;
    padding:12px 0px;
}
.presale .presale_area .presale_grid .each_item .column .item_header{
    color:var(--dark_blue);
    font-size:20px;
    font-family:"Bold";
}
.presale .presale_area .presale_grid .each_item .column .col_title{
    color:var(--deep_grey);
    font-size:16px;
    font-family:"Medium"; 
}
.presale .presale_area .presale_grid .each_item .column .col_ans{
    color:var(--dark_blue);
    font-size:16px;
    font-family:"Heavy"; 
}
.presale .presale_area .presale_grid .each_item .column .col_border{
    position:relative;
    display:block;
    width:90%;
    background-color:#3B3B3B;
    height:2px;
}

@media screen and (max-width:1000px){
    .presale .presale_area .presale_grid {
        column-gap:2rem;
    }
    .presale .presale_area .presale_grid .each_item{
        margin:2rem 0rem;
        width:100%;
        padding: 40px 0px;
    }
}
@media screen and (max-width:769px){
    .presale .presale_area {
        margin:4rem 2rem;
    }
    .presale .presale_area .presale_grid {
        display:flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        row-gap:0rem;
    } 
    .presale .presale_area .presale_grid .each_item{
        margin:2rem 0rem;
        max-width:500px;
        padding: 40px 0px;
    }
    .presale .presale_area .presale_grid .each_item .column .col_title{
        font-size:16px; 
    }
    .presale .presale_area .presale_grid .each_item .column .col_ans{
        font-size:16px;
    }
}