:root{
    --orange:#FDBF6F;
    --red:#FB80AA;
    --green:#33A02C;
    --dark_blue:#0A1846;
    --blue:#1F78B4;
    --black:#363636;
    --light_blue:#A6CEE3;
    --deep_red:#E31A1C;
    --light_green:#B2DF8A;
  }
.navbar_area{
    width:100%;
    height: 80px;
    position: relative;
    z-index: 1000;
    top:0;
}

.navbar_area .navbar_content{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items: center;
    padding:2rem 4rem;
} 
.navbar_area .navbar_content .logo_img{
    cursor: pointer;
    width:100px;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-basis: 30%;
}

.navbar_area .navbar_content .logo_img img{
    width:100%;
    height:100%;
    object-fit:contain;
    z-index: 10;
}
.navbar_area .navbar_content ul{
    flex-basis: 70%;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    column-gap:3rem;
    width:100%;

}

.navbar_area .navbar_content ul li a{
    color:var(--black);
    font-family: "Medium";
    text-decoration:none;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.7s ease-in;
    display:inline-block;
}
.navbar_area .navbar_content  ul li a div{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.navbar_area .navbar_content  ul li a img{
    width: 20px;
    height: 20px;
}
.navbar_area .navbar_content ul li .selector{
   font-size: 14px;
    outline: none;
    border: none;
    font-family: "Medium";
    cursor: pointer;
    color:var(--black);
    background-color: transparent;
    width: auto;
}
.navbar_area .navbar_content ul li .selector{
    display: flex;
    justify-content:space-between;
    align-items: center;
    flex-direction: row;
}

.navbar_area .navbar_content .menu_toggle{
    cursor:pointer;
    display: none;
    position: relative;
}

/* hamburger div */
 .hamburger_menu{
    position: fixed;
    z-index: 10000;
    top:0;
    right:999%;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: white;
    transition: all 1s ease-in;
}

.hamburger_menu .close_btn{
    display: flex;
    justify-content: flex-end;
    padding:40px 15px;
    cursor: pointer;
}
.hamburger_menu .items{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 4rem;
}

.hamburger_menu .items ul li{
    list-style-type: none;
    text-decoration: none;
    margin-bottom:40px;
    margin-left:30px;
}
.hamburger_menu .items ul li a{
  font-size: 25px;
  color: var(--black);
  font-family: "Bold";
  text-decoration: none;
  transition: all 0.7s ease-in;
}
.hamburger_menu .items ul li a div{
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.hamburger_menu .items ul li a div img{
  width: 30px;
  height: 30px;
}
.hamburger_menu .items ul li a .row span{
    display:block;
}
.hamburger_menu .items  ul li  div .selector{
 font-size: 25px;
  outline: none;
  border: none;
  font-family: "Bold";
  cursor: pointer;
  color:var(--black);
  background-color: transparent;
}
.hamburger_menu .items ul li  div .selector option{
  padding:0px 10px;
}

@media screen and (max-width:1100px){
    .navbar_area .navbar_content{
        padding:2rem 1rem;
        align-items: flex-end;
    } 
    .navbar_area .navbar_content ul li{
        padding:0rem 1rem;
    }
}

@media screen and (max-width:869px){
    .navbar_area .navbar_content{
        padding:2rem 1rem;
        align-items: flex-end;
    } 
    .navbar_area .navbar_content ul{
        display: none;
    }
    .navbar_area .navbar_content .menu_toggle{
        display:flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        cursor:pointer;
    } 
   .hamburger_menu.active{
       background-color: white;
       position: fixed;
       top: 0; 
       right:0;
       height: 100%; 
       width: 100%;
       z-index: 10000;
       transition: all 0.6s ease-out;
      } 
}
@media screen and (max-width:600px){
    .navbar_area .navbar_content{
        padding:2rem 1rem;
        align-items: flex-end;
    } 
}