:root{
    --orange:#FDBF6F;
    --red:#FB80AA;
    --green:#33A02C;
    --dark_blue:#0A1846;
    --blue:#1F78B4;
    --black:#363636;
    --light_blue:#A6CEE3;
    --deep_red:#E31A1C;
    --light_green:#B2DF8A;
    --deep_grey:#3B3B3B;
  }

  .error_area{
     width:100%;
     height:100%;
  }

  .error_grid{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height:80vh;
  }
  .error_grid .error_content {
    display: flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
    row-gap:3rem;
  }
.error_grid .error_content p:nth-child(1){
      font-size:50px;
      font-family:"Heavy";
      color:var(--deep_blue);
}
.error_grid .error_content p:nth-child(2){
      font-size:16px;
      font-family:"Medium";
      letter-spacing:0px;
      color:#858383;
      max-width: 550px;
      line-height:30px;
      text-align: center;
}
.error_grid .error_content button{
    font-family: "Bold";
    background-color: var(--dark_blue);
    color: white;
    outline: none;
    border:none;
    width: 220px;
    height: 60px;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    transition: 0.3s ease-in;
    text-decoration: none;
}
.error_grid .error_content button:hover{
    transition: 0.3s ease-in;
    transform: translateY(-10px);
}
@media screen and (max-width:769px){
    .error_grid .error_content{
        margin:0rem 3rem;
    }
    .error_grid .error_content p:nth-child(1){
        font-size:30px;
        text-align: center;
        max-width:400px;
    }
    .error_grid .error_content button{
        width: 220px;
        height: 60px;
    }
    .error_grid .error_content p:nth-child(2){
      line-height:30px;
      text-align: center;
    }
}